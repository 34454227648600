import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import logo from '../../assets/images/logo.png'
import SvgIcon from '../../components/SvgIcon'
import './style.scss'
import { useHistory } from 'react-router-dom'

const SignUp = () => {
  const [data, setData] = useState({
    email: '',
    confirmEmail: '',
    password: '',
  })
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [otp, setOtp] = useState('')

  const history = useHistory()
  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        history.push('/')
      }
    }
    checkIsLoggedIn()
  }, [])

  const handleSignUp = async (e) => {
    e.preventDefault()
    try {
      if (isOtpSent) {
        await Auth.confirmSignUp(data.email, otp)
      } else {
        const { user } = await Auth.signUp({
          username: data.email,
          password: data.password,
          attributes: {
            email: data.email,
            preferred_username: data.email,
          },
        })
        setIsOtpSent(true)
      }
    } catch (error) {
      console.log('error signing up:', error)
    }
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  return (
    <div className="signup" onSubmit={handleSignUp}>
      <div className="signup-wrapper">
        <img src={logo} className="signup-logo" />
        <form className="signup-form">
          <p className="signup-form__label">Sign Up</p>
          {isOtpSent ? (
            <input
              placeholder="Input OTP code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          ) : (
            <>
              <input placeholder="Email" required name="email" onChange={handleChange} />
              <input
                placeholder="Confirm Email"
                required
                name="confirmEmail"
                onChange={handleChange}
              />
              <input
                type="password"
                placeholder="Password"
                required
                name="password"
                onChange={handleChange}
              />
            </>
          )}
          {isOtpSent ? (
            <button type="submit" className="signup-form__signUp">
              Confirm
            </button>
          ) : (
            <button type="submit" className="signup-form__signUp">
              Sign Up
            </button>
          )}
        </form>
        <button className="signup-socialButton">
          <SvgIcon type="facebook" />
          Sign Up with Facebook
        </button>
        <button className="signup-socialButton">
          <SvgIcon type="google" />
          Sign Up with Google
        </button>
        <button className="signup-socialButton signup-socialButton__apple">
          <SvgIcon type="apple" />
          Sign Up with Apple
        </button>
        <p className="signup-helperText">
          Already have an account? <a>Sign In</a>
        </p>
      </div>
    </div>
  )
}

export default SignUp
