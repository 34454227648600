import { Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Document } from 'react-pdf'

const AllItemReport = (props) => {
  const [pdfReport, setPdfReport] = useState(null)
  const [signedUrl, setSignedUrl] = useState(null)

  useEffect(() => {
    const getSignedUrl = async () => {
      let fileName = 'user-reports/' + props.userID + '_all_items_report.pdf'
      const _signedUrl = await Storage.get(fileName, { level: 'guest' })
      setSignedUrl(_signedUrl)
    }
    getSignedUrl()
  })

  const downloadBlob = (blob, filename) => {
    console.log('downloadBlob')
    const url = URL.createObjectURL(blob)
    const a = window.document.createElement('a')
    a.href = url
    setPdfReport(url)
    a.download = filename || 'download'
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener('click', clickHandler)
      }, 150)
    }
    a.addEventListener('click', clickHandler, false)
    a.click()
    return a
  }
  const handleDownload = async () => {
    console.log('handleDownload')
    try {
      if (props.userID) {
        console.log('in props userID handle download')
        console.log(props.userID + '_all_items_report.pdf')
        let fileName = 'user-reports/' + props.userID + '_all_items_report.pdf'
        const result = await Storage.get(fileName, { level: 'guest', download: true })
        console.log('result :' + result)
        downloadBlob(result.Body, fileName)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <div className="insightsPieCharts" onClick={handleDownload}>
        <h3>All Items Report</h3>
        <button className="categories-header__add">Download Report</button>
      </div>
    </div>
  )
}

export default AllItemReport
