import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import GoogleLogin from './googlelogin'
import ThirdParty from './thirdparty.js'
// import SvgIcon from '../../components/SvgIcon'
import './style.scss'
// import { getUser } from '../../utils/auth'
const Login = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const history = useHistory()
  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        history.push('/home')
      }
    }
    checkIsLoggedIn()
  }, [])

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      await Auth.signIn(data.email, data.password)
      history.push('/home/no_propertyId')
    } catch (err) {
      return false
    }
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  return (
    <div className="login" onSubmit={handleSignIn}>
      <div className="login-wrapper">
        <img src={logo} className="login-logo" />
        <form className="login-form">
          <p className="login-form__label">Sign in</p>
          <input placeholder="Email" required name="email" onChange={handleChange} />
          <input
            type="password"
            placeholder="Password"
            required
            name="password"
            onChange={handleChange}
          />
          <button type="submit" className="login-form__signUp">
            Sign In
          </button>
        </form>
      </div>
      <ThirdParty />
    </div>
  )
}

export default Login
