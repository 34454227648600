import { DataStore, Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LabelComponent from './LabelComponent'
import { LabelItem } from '../models'
import { getTimeDifference } from '../utils/helper'

const Product = ({ data }) => {
  console.log(data)
  const history = useHistory()
  const { id, name, currentValue, currency, thumbnail, createdDate } = data
  const [signedUrl, setSignedUrl] = useState('')
  const [labels, setLabels] = useState([])

  const getSignedUrl = async () => {
    const signedUrl = await Storage.get(thumbnail.key, { level: 'private' })
    setSignedUrl(signedUrl)
  }

  useEffect(() => {
    const getLabels = async () => {
      const _labels = await DataStore.query(LabelItem, (c) => c.itemID('eq', id))
      setLabels(_labels)
    }
    getLabels()
    getSignedUrl()
  }, [])

  return (
    <div
      className="product"
      onClick={() => history.push(`/category/${data.categoryID}/inventory/${id}`)}
    >
      <div className="product-wrapper">
        <img src={signedUrl} />
        <div>
          <p className="product-wrapper__productName">{name}</p>
          <div className="product-wrapper__productLabels">
            {labels.map((label) => (
              <LabelComponent key={label.id} data={label} />
            ))}
          </div>
        </div>
      </div>
      <div className="product-priceAndDate">
        <p className="product-priceAndDate__price">{`${currency || '$'}${currentValue || 0}`}</p>
        <p className="product-priceAndDate__date">{getTimeDifference(createdDate)}</p>
      </div>
    </div>
  )
}

export default Product
