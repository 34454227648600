import appStore from '../assets/images/appstore.png'

const Footer = () => {
  return (
    <div className="footer">
      <p>Download the #1 Inventory app</p>
      <a href="https://appstore.com" target="_blank" rel="noreferrer">
        <img src={appStore} />
      </a>
    </div>
  )
}

export default Footer
