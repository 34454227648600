import { Actions } from '../actions'

const defaultState = {
  currentCategory: '',
  data: [],
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case Actions.SET_PRODUCTS:
      return { ...state, data: action.data }
    case Actions.SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: action.data }
    default:
      return state
  }
}
