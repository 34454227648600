import React, { Component } from 'react'
import Amplify, { Auth, Hub } from 'aws-amplify'
import awsconfig from '../../aws-exports.js'
Amplify.configure(awsconfig)

export default class ThirdParty extends Component {
  state = { user: null, customState: null }

  componentDidMount() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('signIn')
          this.setState({ user: data })
          break
        case 'signOut':
          console.log('sign Out')
          this.setState({ user: null })
          break
        case 'customOAuthState':
          console.log('customOAuthState')
          this.setState({ customState: data })
      }
    })

    Auth.currentAuthenticatedUser()
      .then((user) => this.setState({ user }))
      .catch(() => console.log('Not signed in'))
  }

  render() {
    const { user } = this.state

    return (
      <div className="App">
        <button onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}>
          Open Facebook
        </button>
        <button onClick={() => Auth.federatedSignIn({ provider: 'google' })}>Open Google</button>
        <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>
        {/* <button onClick={() => Auth.signOut()}>Sign Out {user.getUsername()}</button> */}
      </div>
    )
  }
}
