import { DataStore } from 'aws-amplify'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import ChevronRight from '../../components/ChevronRight'
import LabelComponent from '../../components/LabelComponent'
import { Category, Item, LabelItem } from '../../models'
import Document from './Document'
import ProductImages from './ProductImages'
import './style.scss'

const InventoryDetails = () => {
  const { categoryId, inventoryId } = useParams()
  const history = useHistory()
  const [labels, setLabels] = useState([])
  const [currentProduct, setCurrentProduct] = useState({})
  const [category, setCategory] = useState({})

  useEffect(() => {
    const getProduct = async () => {
      const _category = (await DataStore.query(Category, (c) => c.id('eq', categoryId)))[0]
      setCategory(_category)
      const _product = await DataStore.query(Item, (c) => c.id('eq', inventoryId))
      if (!_product) {
        history.goBack()
        return
      }
      setCurrentProduct(_product[0])
      const _labels = await DataStore.query(LabelItem, (c) => c.itemID('eq', inventoryId))
      setLabels(_labels)
    }
    getProduct()
  }, [])

  return (
    <div className="inventoryDetails">
      <div className="inventoryDetails-header">
        <p className="categories-header__route">
          <Link to="/">
            <span>Categories</span>
          </Link>
          <ChevronRight />
          <Link to={`/category/${categoryId}`}>
            <span>{category?.name}</span>
          </Link>
          <ChevronRight />
          <Link to={`/category/${categoryId}/inventory/${inventoryId}`}>
            <span>{currentProduct?.name || 'test'}</span>
          </Link>
        </p>
        <div className="inventoryDetails-header__row">
          <h2>{currentProduct?.name || 'test'}</h2>
          <div>
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00781 11.6484C7.34375 11.6484 7.625 11.375 7.625 11.0391V3.5L7.57812 2.39844L8.0625 2.92969L9.15625 4.09375C9.26562 4.21875 9.42188 4.28125 9.57031 4.28125C9.89062 4.28125 10.1406 4.05469 10.1406 3.73438C10.1406 3.57031 10.0703 3.44531 9.96094 3.32812L7.46875 0.921875C7.30469 0.765625 7.16406 0.710938 7.00781 0.710938C6.84375 0.710938 6.70312 0.765625 6.54688 0.921875L4.05469 3.32812C3.9375 3.44531 3.86719 3.57031 3.86719 3.73438C3.86719 4.05469 4.10938 4.28125 4.42969 4.28125C4.57812 4.28125 4.75 4.21875 4.85938 4.09375L5.94531 2.92969L6.42969 2.39844L6.38281 3.5V11.0391C6.38281 11.375 6.66406 11.6484 7.00781 11.6484ZM2.75781 17.4766H11.2422C12.8125 17.4766 13.6016 16.6875 13.6016 15.1484V7.8125C13.6016 6.26562 12.8125 5.47656 11.2422 5.47656H9.21094V6.76562H11.2031C11.9141 6.76562 12.3203 7.14844 12.3203 7.89844V15.0625C12.3203 15.8125 11.9141 16.1953 11.2031 16.1953H2.80469C2.08594 16.1953 1.6875 15.8125 1.6875 15.0625V7.89844C1.6875 7.14844 2.08594 6.76562 2.80469 6.76562H4.80469V5.47656H2.75781C1.19531 5.47656 0.398438 6.26562 0.398438 7.8125V15.1484C0.398438 16.6953 1.19531 17.4766 2.75781 17.4766Z"
                fill="#292F4F"
              />
            </svg>
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.7749 2.33887C3.7749 1.45068 3.06104 0.736816 2.15625 0.736816C1.26807 0.736816 0.545898 1.45068 0.545898 2.33887C0.545898 3.22705 1.26807 3.94092 2.15625 3.94092C3.05273 3.94092 3.7749 3.22705 3.7749 2.33887ZM9.59375 2.33887C9.59375 1.45068 8.88818 0.736816 8 0.736816C7.11182 0.736816 6.39795 1.45068 6.39795 2.33887C6.39795 3.22705 7.11182 3.94092 8 3.94092C8.87988 3.94092 9.59375 3.22705 9.59375 2.33887ZM15.4458 2.33887C15.4458 1.45068 14.7319 0.736816 13.8438 0.736816C12.939 0.736816 12.2251 1.45068 12.2251 2.33887C12.2251 3.22705 12.939 3.94092 13.8438 3.94092C14.7236 3.94092 15.4458 3.22705 15.4458 2.33887Z"
                fill="#292F4F"
              />
            </svg>
          </div>
        </div>
      </div>
      <ProductImages images={currentProduct.images} />
      <div className="inventoryDetails-labels">
        {labels.map((label) => (
          <LabelComponent key={`${label.id}-${(Math.random() % 100) * 1000}`} data={label} />
        ))}
      </div>
      <div className="inventoryDetails-details">
        <div className="inventoryDetails-details__item">
          <p>Initial Value</p>
          <p>{currentProduct?.currentValue}</p>
        </div>
        <div className="inventoryDetails-details__item">
          <p>Current Value</p>
          <p>{currentProduct?.retailValue}</p>
        </div>
        <div className="inventoryDetails-details__item">
          <p>Purchase Date</p>
          <p>
            {currentProduct?.purchaseDate &&
              moment(currentProduct?.purchaseDate).format('MMM DD, YYYY')}
          </p>
        </div>
        <div className="inventoryDetails-details__item">
          <p>Warranty Expiration</p>
          <p>
            {currentProduct?.manufactureWarratyExpirationDate &&
              moment(currentProduct?.manufactureWarratyExpirationDate).format('MMM DD, YYYY')}
          </p>
        </div>
        <div className="inventoryDetails-details__item--documents">
          <p>Document</p>
          <div className="inventoryDetails-details__documentsWrapper">
            {console.log(currentProduct)}
            {currentProduct?.documents?.map((document, index) => (
              <Document key={`${document.id}-${index}`} document={document} />
            ))}
          </div>
        </div>
        <div className="inventoryDetails-details__item inventoryDetails-details__item--note">
          <p>Note</p>
          <p>{currentProduct?.notes}</p>
        </div>
      </div>
    </div>
  )
}

export default InventoryDetails
