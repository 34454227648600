import * as productsActions from './products.actions'

export const Actions = {
  // Categories
  SET_CATEGORIES: 'SET_CATEGORIES',

  // Properties
  SET_PROPERTIES: 'SET_PROPERTIES',

  // Products(Inventories)
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_CURRENT_CATEGORY: 'SET_CURRENT_CATEGORY',

  SET_CURRENT_PROPERTY: 'SET_CURRENT_PROPERTY',
  // Search
  SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',

  // Loading
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
}

export const startLoading = (data) => ({
  type: Actions.START_LOADING,
  data,
})

export const stopLoading = (data) => ({
  type: Actions.STOP_LOADING,
  data,
})

export const setCategories = (data) => ({
  type: Actions.SET_CATEGORIES,
  data,
})

export const setProperties = (data) => ({
  type: Actions.SET_PROPERTIES,
  data,
})

export { productsActions }
