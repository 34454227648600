import { Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import PieChartValue from './PieChartValue'
import PieChartCost from './PieChartCost'
import { Auth } from 'aws-amplify'
import './style.scss'
import AllItemReport from './AllItemReport'
// import { getUser } from '../../utils/auth'
const Reports = () => {
  const [user, setUser] = useState()
  const [userID, setUserID] = useState('')

  useEffect(() => {
    const getUser = async () => {
      const _user = await Auth.currentAuthenticatedUser()
      setUser(_user)
      setUserID(_user.attributes.sub)
    }
    getUser()
  }, [])

  return (
    <div className="reports">
      <h2>Insights</h2>
      <div className="flexbox-container_pie_chart">
        <PieChartValue userID={userID} />
        <PieChartCost userID={userID} />
      </div>
      <AllItemReport userID={userID} />
    </div>
  )
}

export default Reports
