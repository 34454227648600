import all from './all.svg'
import antiques from './antiques.svg'
import art from './art.svg'
import awards from './awards.svg'
import bags from './bags.svg'
import basketball from './basketball.svg'
import bathroom from './bathroom.svg'
import bbq from './bbq.svg'
import bed from './bed.svg'
import bench from './bench.svg'
import bike from './bike.svg'
import boat from './boat.svg'
import books from './books.svg'
import box from './box.svg'
import camping from './camping.svg'
import car from './car.svg'
import chair2 from './chair 2.svg'
import chair1 from './chair-1.svg'
import chair from './chair.svg'
import clothes from './clothes.svg'
import collector from './collector.svg'
import cosmetics from './cosmetics.svg'
import cutlery from './cutlery.svg'
import diving from './diving.svg'
import drawers from './drawers.svg'
import electronics from './electronics.svg'
import equipment from './equipment.svg'
import fishing from './fishing.svg'
import fitness from './fitness.svg'
import gaming from './gaming.svg'
import glass from './glass.svg'
import guitar from './guitar.svg'
import heels from './heels.svg'
import hobby from './hobby.svg'
import jewerly1 from './jewerly-1.svg'
import jewerly from './jewerly.svg'
import kayak from './kayak.svg'
import keds from './keds.svg'
import kidsRoom from './kids room.svg'
import kitchen1 from './kitchen-1.svg'
import kitchen from './kitchen.svg'
import knitting from './knitting.svg'
import library from './library.svg'
import lighting from './lighting.svg'
import moto from './moto.svg'
import office from './office.svg'
import photo from './photo.svg'
import piano from './piano.svg'
import plants from './plants.svg'
import property from './property.svg'
import sewing from './sewing.svg'
import shoes from './shoes.svg'
import skate from './skate.svg'
import sofa from './sofa.svg'
import spirit from './spirit.svg'
import storage from './storage.svg'
import surf from './surf.svg'
import suv from './suv.svg'
import tableTennis from './table tennis.svg'
import table from './table.svg'
import telescope from './telescope.svg'
import toilet from './toilet.svg'
import tools from './tools.svg'
import vinyl from './vinyl.svg'
import wardrobe from './wardrobe.svg'
import watches from './watches.svg'
import wine from './wine.svg'
import yoga from './yoga.svg'

const Images = {
  'all.svg': all,
  'nw_all items-1.svg': all,
  'antiques.svg': antiques,
  art: art,
  'art.svg': art,
  'awards.svg': awards,
  'bags.svg': bags,
  'basketball.svg': basketball,
  'bathroom.svg': bathroom,
  'bbq.svg': bbq,
  'bed.svg': bed,
  'bench.svg': bench,
  'bike.svg': bike,
  'boat.svg': boat,
  'books.svg': books,
  'box.svg': box,
  'camping.svg': camping,
  'car.svg': car,
  'chair 2.svg': chair2,
  'chair-1.svg': chair1,
  'chair.svg': chair,
  'clothes.svg': clothes,
  'collector.svg': collector,
  'cosmetics.svg': cosmetics,
  'cutlery.svg': cutlery,
  'diving.svg': diving,
  'drawers.svg': drawers,
  'electronics.svg': electronics,
  'equipment.svg': equipment,
  'fishing.svg': fishing,
  'fitness.svg': fitness,
  'gaming.svg': gaming,
  'glass.svg': glass,
  'guitar.svg': guitar,
  'heels.svg': heels,
  'hobby.svg': hobby,
  'jewerly-1.svg': jewerly1,
  'jewerly.svg': jewerly,
  'kayak.svg': kayak,
  'keds.svg': keds,
  'kids room.svg': kidsRoom,
  'kitchen-1.svg': kitchen1,
  'kitchen.svg': kitchen,
  'knitting.svg': knitting,
  'library.svg': library,
  'lighting.svg': lighting,
  'moto.svg': moto,
  'office.svg': office,
  'photo.svg': photo,
  'piano.svg': piano,
  'plants.svg': plants,
  'property.svg': property,
  'sewing.svg': sewing,
  'shoes.svg': shoes,
  'skate.svg': skate,
  'sofa.svg': sofa,
  'spirit.svg': spirit,
  'storage.svg': storage,
  'surf.svg': surf,
  'suv.svg': suv,
  'table tennis.svg': tableTennis,
  'table.svg': table,
  'telescope.svg': telescope,
  'toilet.svg': toilet,
  'tools.svg': tools,
  'vinyl.svg': vinyl,
  'wardrobe.svg': wardrobe,
  'watches.svg': watches,
  'wine.svg': wine,
  'yoga.svg': yoga,
}

export default Images
