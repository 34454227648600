import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataStore, Storage } from 'aws-amplify'
import { Category } from '../../models'
import Images from '../../assets/images'

const Banner = ({ category, itemCount }) => {
  const [signedUrl, setSignedUrl] = useState('')

  useEffect(() => {
    const getSignedUrl = async () => {
      const { image } = category
      if (!image?.isAsset) {
        const _signedUrl = await Storage.get(image?.key, { level: 'private' })
        setSignedUrl(_signedUrl)
      }
    }
    getSignedUrl()
  })
  return (
    <div className="category-items__banner">
      {category.image?.isAsset ? (
        <img src={Images[category.image?.key]} />
      ) : (
        <img src={signedUrl} />
      )}
      <h2>{category.name}</h2>
      <p>{itemCount} items</p>
    </div>
  )
}

export default Banner
