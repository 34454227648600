import { Actions } from '.'

export const setSearchTerm = (data) => ({
  type: Actions.SET_SEARCH_TERM,
  data,
})

export const setSearchResult = (data) => ({
  type: Actions.SET_SEARCH_RESULT,
  data,
})
