import { Storage } from 'aws-amplify'
import React, { Fragment, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { FaRegQuestionCircle } from 'react-icons/fa'
import './style.scss'
import DisplayParseResults from './parseresults'
import { nanoid } from 'nanoid'

const DragAndDrop = () => {
  const [user, setUser] = useState()
  const [result, setResult] = useState()
  const [resultArr, setResultArr] = useState()
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [dropSuccess, setDropSuccess] = useState(null)
  const [uploadStringMesg, setUploadStringMesg] = useState('')
  const [uploadEditFileSuccess, setUploadEditFileSuccess] = useState(false)

  useEffect(() => {
    const getUser = async () => {
      const _user = await Auth.currentAuthenticatedUser()
      setUser(_user)
    }
    getUser()
  }, [])

  useEffect(() => {
    console.log('uploadStringMesg' + uploadStringMesg)
    var localFileName = uploadStringMesg
    var api_parser = `https://r2uvsma7lc.execute-api.us-west-2.amazonaws.com/default/minvyprocessamazoncsv-staging?key=`
    var urlString = api_parser + localFileName
    fetch(urlString)
      .then((response) => response.json())
      .then(setResult)
      .then(console.log(result))
  }, [uploadSuccess])

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth }
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone }
      case 'ADD_FILE_TO_LIST':
        return { ...state, fileList: (state.fileList[0] = action.files) }
      default:
        return state
    }
  }

  const [data, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  })

  const handleDragEnter = (e) => {
    console.log('in drag area')
    console.log({ user })
    e.preventDefault()
    e.stopPropagation()

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 })
  }

  const handleDragLeave = (e) => {
    console.log('leave drag area')
    e.preventDefault()
    e.stopPropagation()

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 })
    if (data.dropDepth > 0) return
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
  }

  const handleDragOver = (e) => {
    console.log(' drag over')

    e.preventDefault()
    e.stopPropagation()

    e.dataTransfer.dropEffect = 'copy'
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true })
  }

  const handleDrop = (e) => {
    console.log('drop in')
    e.preventDefault()
    e.stopPropagation()

    let files = [...e.dataTransfer.files]

    if (files && files.length > 0) {
      //const existingFiles = data.fileList.map((f) => f.name)

      dispatch({ type: 'ADD_FILE_TO_LIST', files })
      dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 })
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
      setDropSuccess(true)
    }
  }

  const handleUpload = async () => {
    try {
      if (data.fileList.length) {
        for (const file of data.fileList) {
          const now = new Date()
          var blob = file.slice(0, file.size)
          // var str = now.toISOString()
          // var res = str.replace(/:/g, '_')
          //var fileName = 'AMZ' + + res + '.csv'
          var fileName = 'AMZ' + user.attributes.sub + '-' + now.toISOString() + '.csv'
          var newFile = new File([blob], fileName)

          const result = await Storage.put(newFile.name, newFile, {
            contentType: file.type,
          })
          console.log(result)
          setUploadStringMesg(fileName)
        }
        // alert('Files successfully uploaded to your Account' + uploadStringMesg)
        console.log('uploadString ' + uploadStringMesg)
        //need to handle success and failure modes here
        setUploadSuccess(true)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleUploadEditFiles = async () => {
    try {
      if (result.length) {
        // for (const file of result) {
        // convert to CSV
        // var jsonObject = JSON.stringify(result)
        // var csvObject = jsonObject.text(ConvertToCSV(jsonObject))
        let csvFile = JSONToCSVConvertor(result)

        const now = new Date()
        // var blob = csvFile.slice(0, csvFile.size)

        var fileName = 'FINAL' + user.attributes.sub + now.toISOString() + '.csv'
        // var newFile = new File(blob, fileName)

        const response = await Storage.put(fileName, csvFile, {
          contentType: csvFile.type,
        })
        console.log(response)
        setUploadStringMesg(fileName)
        // }
        // alert('Files successfully uploaded to your Account' + uploadStringMesg)
        console.log('uploadString ' + uploadStringMesg)
        //need to handle success and failure modes here
        setUploadEditFileSuccess(true)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const clearUpload = async () => {
    try {
      console.log('file list length ' + data.fileList.length)
      if (data.fileList.length) {
        data.fileList = []
        console.log('file list length' + data.fileList.length)
      }
      setUploadSuccess(null)
      setResult([])
    } catch (e) {
      console.log(e)
    }
  }
  const JSONToCSVConvertor = (JSONData) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

    var CSV = ''

    //This condition will generate the Label/Header

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = ''

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",'
      }

      row.slice(0, row.length - 1)

      //add a line break after each row
      CSV += row + '\r\n'
    }

    if (CSV === '') {
      alert('Invalid data')
      return
    }
    var contentType = 'text/csv'
    var csvFile = new Blob([CSV], { type: contentType })
    return csvFile
  }
  const deleteSelected = async () => {
    console.log('delete selected')
  }
  const toggleSelected = (id) => {
    console.log('toggle selected')
    console.log(id)
  }

  return (
    <div>
      <div className="upload-title">
        <h2>Upload</h2>
      </div>
      <Fragment>
        {!uploadSuccess ? (
          <div>
            <div className="header-nav">
              <h3>
                <br></br>
                Upload your Amazon order history into Minvy.
                <a
                  rel="noopener noreferrer"
                  href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GVWV7WNZCNSXVW66"
                  target="_blank"
                >
                  <FaRegQuestionCircle
                    className="question_iconr"
                    color=" lightgray"
                    //onClick={() => {}}
                    name="question_iconr"
                  ></FaRegQuestionCircle>
                </a>
                <br></br>
                <br></br>
              </h3>
            </div>
            <div
              className={data.inDropZone ? 'drag-drop-zone inside-drag-area' : 'drag-drop-zone'}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <p className="drag-drop-zonep">Drag file here to upload to Minvy</p>
              <ol className="dropped-files">
                {data.fileList.map((f) => {
                  return <h2 key={f.name}>{f.name}</h2>
                })}
              </ol>
            </div>
            <div className="upload_button">
              {data.fileList.length === 0 ? null : (
                <button type="submit" className="signup-form__signUp" onClick={handleUpload}>
                  Upload
                </button>
              )}
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="relative">
              {data.fileList.length === 0 ? null : (
                <button className="edit_button" type="submit" onClick={clearUpload}>
                  Clear
                </button>
              )}
              {data.fileList.length === 0 ? null : (
                <button className="edit_button" type="submit" onClick={handleUploadEditFiles}>
                  Submit
                </button>
              )}
              <br />
            </div>

            <DisplayParseResults result={result} toggleSelected={toggleSelected} />
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default DragAndDrop
