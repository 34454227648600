import { Actions } from '../actions'

const defaultState = {
  searchTerm: '',
  result: [],
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case Actions.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.data }
    case Actions.SET_SEARCH_RESULT:
      return { ...state, result: action.data }
    default:
      return state
  }
}
