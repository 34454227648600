import { DataStore } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchResult } from '../../actions/search.actions'

import Product from '../../components/Product'
import { RecentSearchQueries } from '../../models'
import './style.scss'

const SearchResult = () => {
  const dispatch = useDispatch()
  const result = useSelector((state) => state.search.result)
  const [recentKeywords, setRecentKeywords] = useState([])
  // const key = 'recentKeywords'
  // const recentKeywords = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []

  useEffect(() => {
    const getRecentSearchQueries = async () => {
      const keywords = await DataStore.query(RecentSearchQueries)
      setRecentKeywords(keywords)
    }
    getRecentSearchQueries()
    return () => {
      dispatch(setSearchResult([]))
      console.log('ahoy!')
    }
  }, [])

  return (
    <div className="searchResult">
      <div className="searchResult__recentTitle">
        <p>Recent searches</p>
        <div>&times;</div>
      </div>
      <div className="searchResult-recentKeywordsWrapper">
        {recentKeywords.length
          ? recentKeywords.map((keyword) => (
              <p className="searchResult-recentKeywordsWrapper__keyword" key={keyword}>
                {keyword}
              </p>
            ))
          : null}
      </div>
      {result.length ? <p className="searchResult__description">Search results</p> : null}
      {result.map((data) => (
        <Product key={data.id} data={data} />
      ))}
    </div>
  )
}

export default SearchResult
