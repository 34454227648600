import { Actions } from '../actions'

const defaultState = []

export default function (state = defaultState, action) {
  switch (action.type) {
    case Actions.SET_PROPERTIES:
      console.log('SET_PROPERTIES')
      return { ...state, data: action.data }
    default:
      console.log('set categories default')
      return state
  }
}
