import React, { useState, useEffect } from 'react'
import { FaTrash } from 'react-icons/fa'
import { EditText, EditTextarea } from 'react-edit-text'

import Select, { components } from 'react-select'
import 'react-edit-text/dist/index.css'
import './style.scss'

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected })
    return {
      ...styles,
      backgroundColor: isFocused ? '#6c7ae0' : 'white',
      font: 'Segoe UI',
      color: 'black',
    }
  },
}

const CsvResult = ({ data, toggleSelected, propertyList, categoryList }) => {
  const [selectedProperty, setSelectedProperty] = useState([])
  const [optionSelectedList, setOptionSelectedList] = useState([])
  const [defaultValue, setDefaultValue] = useState('')

  var categories = []
  const onPropertyChange = (option) => {
    var orig_cat = []
    data.property_id = option.value

    var defaultValueJSON = JSON.parse(
      '{ "label":"' + category + '", "value":"' + category + '", "description":"' + category + '"}'
    )
    // setDefaultValue(defaultValueJSON)
    // orig_cat.push(defaultValueJSON)
    categories = categoryList[option.value]
    orig_cat.push.apply(orig_cat, categories)
    setOptionSelectedList(orig_cat)
  }
  const handleSave = ({ name, value }) => {
    switch (name) {
      case 'title':
        data.title = value
        break
      case 'category':
        data.category = value
        break
      case 'description':
        data.description = value
        break
      case 'order_date':
        data.order_date = value
        break
      case 'price_per_unit':
        data.price_per_unit = value
        break
      case 'quantity':
        data.quantity = value
        break
      default:
        return
    }
  }

  useEffect(() => {
    var orig_cat = []
    // var defaultValueJSON = JSON.parse(
    //   '{ "label":"' + category + '", "value":"' + category + '", "description":"' + category + '"}'
    // )
    // setDefaultValue(defaultValueJSON)
    // orig_cat.push(defaultValueJSON)

    categories = categoryList[propertyList[0].value]
    console.log(categories)
    orig_cat.push.apply(orig_cat, categories)
    setOptionSelectedList(orig_cat)
  }, [categoryList])

  useEffect(() => {
    data.property_id = propertyList[0].value
  }, [])
  const {
    asin,
    property_id,
    category,
    description,
    id,
    order_date,
    price_per_unit,
    quantity,
    shipping_zip,
    title,
    unspsc,
    is_delete,
  } = data
  const [deleted, setDeleted] = useState(false)
  const [checked, setChecked] = useState(false)
  if (is_delete == 'false') {
    return (
      <tr>
        <td>
          <EditText name="title" defaultValue={title} onSave={handleSave} />
        </td>
        <td>
          <Select
            defaultValue={optionSelectedList[1]}
            label="Single select"
            options={optionSelectedList}
            styles={colourStyles}
          />
        </td>
        <td>
          <EditText name="description" defaultValue={description} onSave={handleSave} />
        </td>
        <td>
          <EditText type="date" name="order_date" defaultValue={order_date} onSave={handleSave} />
        </td>
        <td>
          <EditText name="price_per_unit" defaultValue={price_per_unit} onSave={handleSave} />
        </td>
        <td>
          <EditText name="quantity" defaultValue={quantity} onSave={handleSave} />
        </td>
        <td>
          <Select
            options={propertyList}
            label="Single select"
            onChange={onPropertyChange}
            defaultValue={propertyList[0]}
            styles={colourStyles}
          />
        </td>
        <td justify-content="bottom">
          <FaTrash
            className="trash_iconr"
            // color=" lightgray"
            onClick={() => {
              setDeleted((deleted) => !deleted)
              data.is_delete = 'true'
              toggleSelected(id) //pass up to parent component -if we need it otherwise remove
            }}
            name="trash-outline"
          ></FaTrash>
        </td>
      </tr>
    )
  }
  return <></>
}
export default CsvResult
