import { DataStore } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { setProducts, setCurrentCategory } from '../../actions/products.actions'
import { Category, Item } from '../../models'
import Product from '../../components/Product'
import './style.scss'
import Banner from './Banner'
import ChevronRight from '../../components/ChevronRight'

const Items = () => {
  const dispatch = useDispatch()
  const products = useSelector((state) => state.products.data)
  const { categoryId } = useParams()
  const [category, setCategory] = useState({})

  useEffect(() => {
    dispatch(setCurrentCategory(categoryId))
    const getProducts = async () => {
      const _products = await DataStore.query(Item, (c) => c.categoryID('eq', categoryId))
      const _category = (await DataStore.query(Category, (c) => c.id('eq', categoryId)))[0]
      setCategory(_category)
      dispatch(setProducts(_products))
    }
    getProducts()
  }, [])

  return (
    <div className="category-items">
      <div className="category-items__header">
        <p className="categories-header__route">
          <Link to="/">
            <span>Categories</span>
          </Link>
          <ChevronRight />
          <Link to={`/category/${categoryId}`}>
            <span>{category.name}</span>
          </Link>
        </p>
      </div>
      <Banner category={category} itemCount={products.length} />
      {products.map((item) => (
        <Product key={item.id} data={item} />
      ))}
    </div>
  )
}

export default Items
