import { Actions } from '../actions'

const defaultState = []

export default function (state = defaultState, action) {
  switch (action.type) {
    case Actions.SET_CATEGORIES:
      return action.data
    default:
      return state
  }
}
