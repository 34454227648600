import { Auth } from 'aws-amplify'
import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'

const PrivateRoute = ({ path, children }) => {
  const history = useHistory()
  useEffect(() => {
    const checkIsLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (!user) {
          history.push('/login')
        }
      } catch (err) {
        history.push('/login')
      }
    }
    checkIsLoggedIn()
  }, [])

  return <Route path={path}>{children}</Route>
}

export default PrivateRoute
