export const getTimeDifference = (date) => {
  const now = new Date().getTime()
  const difference = now - new Date(date).getTime()
  const seconds = Math.round(difference / 1000)
  const minutes = Math.round(seconds / 60)
  const hours = Math.round(minutes / 60)
  const days = Math.round(hours / 24)
  const months = Math.round(days / 30)
  const years = Math.round(months / 12)
  return years > 0
    ? `${years} years ago`
    : months > 0
    ? `${months} months ago`
    : days >= 1
    ? `${days} days ago`
    : hours > 0
    ? `${hours} hours ago`
    : minutes > 0
    ? `${minutes} minutes ago`
    : `${seconds} seconds ago`
}

export const removeDuplicates = (array) => array.filter((item, pos) => array.indexOf(item) == pos)
