import { Actions } from '.'

export const setProducts = (data) => ({
  type: Actions.SET_PRODUCTS,
  data,
})

export const setCurrentCategory = (data) => ({
  type: Actions.SET_CURRENT_CATEGORY,
  data,
})

export const setCurrentProperty = (data) => ({
  type: Actions.SET_CURRENT_PROPERTY,
  data,
})
