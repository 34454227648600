import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import promise from 'redux-promise'
import { createLogger } from 'redux-logger'

import createRootReducer from './reducers'
import * as actionCreators from './actions'
import App from './App'
import './index.scss'
import './sass/_global.scss'
import './sass/_typography.scss'

const history = createBrowserHistory({
  basename: '/publishing/',
})

let middleware
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger()
  middleware = applyMiddleware(routerMiddleware(history), promise, thunkMiddleware, logger)
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    middleware = compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__({
        actionCreators,
        serialize: true,
        trace: true,
      })
    )
  }
} else {
  middleware = applyMiddleware(routerMiddleware(history), promise, thunkMiddleware)
}
const store = createStore(createRootReducer(history), middleware)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
