import React from 'react'
import all_logo from '../../assets/images/all_logo.png'
import './style.scss'

const Help = () => (
  <div className="help">
    <div className="image-txt-container">
      <img src={all_logo} width="40" height="40" />
      <h2> Minvy FAQs</h2>
    </div>
    <h4>
      <b>Minvy</b>
    </h4>
    <ul>
      <li>What is Minvy?</li>
      <ul>
        <li>
          Minvy (My Inventory) is designed to make it as easy as possible to create an inventory
        </li>
      </ul>
      <li>How ?</li>
      <ul>
        <li>
          You can simply take pictures of your items as a first step then add/edit as you go along
        </li>
        <li>
          Minvy allows you to create customisable lables to better search, tag and create lists of
          important to you - such as for emergencies, storage or items you want to sell.
        </li>
        <li>
          Minvy has a voice assistant for those hands-free moments or to add a narrative to your
          already added items.
        </li>
        <li>
          We have has a voice assistant for those hands-free moments or to add a narrative to your
          already added items.
        </li>
        <li>
          Minvy has AI technology to scan your videos to take the strain out of labeling everything
          in your home or business.
        </li>
      </ul>
      <li>Why ?</li>
      <ul>
        <li>
          In these unpredictable times it is more important than ever that you have a good idea of
          what you have and what you need when you need it
        </li>
      </ul>
    </ul>
    <h4>
      <b>Categories</b>
    </h4>
    <ul>
      <li>How do I delete a category ?</li>
      <ul>
        <li>
          Settings -&gt; Categories -&gt; Delete{' '}
          <i> (Note - all items under that category will be permanently deleted) </i>
        </li>
      </ul>
      <li>How do I add a category ?</li>
      <ul>
        <li>
          Settings -&gt; Categories -&gt; Add{' '}
          <i>
            {' '}
            Select an existing icon or use one of your images to represent the category, add a title
            and save.{' '}
          </i>
        </li>
      </ul>
      <li>How do I move an item to a different category ?</li>
      <ul>
        <li>
          Settings -&gt; Category List -&gt; Item -&gt; Edit Item
          <i> Select desired category from the drop down list of categories </i>
        </li>
      </ul>
    </ul>
    <h4>
      <b>How To Add Items</b>
    </h4>
    <h4>We provide three options for adding items which are discussed below :</h4>
    <ul>
      <li>Add an item manually</li>
      <ul>
        <li>
          Using Add Item, select the image(s) that represent the items you wish to add and select a
          category to which you want to add them to You can add more details to the item -
          description, cost, value, receipts etc once you have created the initial entry
        </li>
      </ul>
      <li>Add Items with Minvy AI</li>
      <ul>
        <li>
          Minvy will evaluate your images/videos and attempt to identify as many relevant items as
          possible possible possible Once Minvy has completed this task (which may take several
          minutes depending on the scale and complexity of the images) it will notify you via the
          You may then select, edit and add whichever results are appropriate and delete the ones
          that aren&#39;t a good match for your needs
        </li>
      </ul>
      <li>Add Items with Minvy Assistant</li>
      <ul>
        <li>
          You can tell Minvy to add items via Minvy Assistant - this may be useful if you want to
          operate hands free and come back at a later stage to add more details and images
        </li>
      </ul>
      <li>How do I move an item to a different category ?</li>
      <ul>
        <li>
          Settings -&gt; Category List -&gt; Item -&gt; Edit Item
          <i> Select desired category from the drop down list of categories </i>
        </li>
      </ul>
    </ul>
    <h4>
      <b>Images and Videos</b>
    </h4>
    <ul>
      <li>Not all of my images are showing on my device, what’s up ?</li>
      <ul>
        <li>Check that you have enabled photo sharing in your phone&#39;s settings.</li>
        <li>
          If your images are hosted on Apple iCloud make sure that you have sync turned on in
          settings and that you have authenticated within iCloud.
        </li>
      </ul>
    </ul>
    <h4>
      <b>Multiple Properties</b>
    </h4>
    <ul>
      <li>Does Minvy support more than one address/location ?</li>
      <ul>
        <li>Yes</li>
      </ul>
      <li>How to I add a new location ?</li>
      <ul>
        <li>Settings -&gt; Locations -&gt; &#39;+&#39;</li>
      </ul>
      <li>How do I switch between locations ?</li>
      <ul>
        <li>If you have more that one location you can swipe between them on the home screen</li>
      </ul>
      <li>How do I delete a location ?</li>
      <li>
        Settings -&gt; Locations -&gt; Select Location and click the trash icon
        <i> (Note - all items under that location will be permanently deleted) </i>
      </li>
    </ul>
    <h4>
      <b>Data and Privacy</b>
    </h4>
    <ul>
      <li>Who can see my data?</li>
      <ul>
        <li>Only You can see your data</li>
      </ul>
      <li>Where is my data stored ?</li>
      <ul>
        <li>
          Your data is stored on your phone and backed-up securely on Amazon’s servers - Minvy
          doesn’t have access to your personally idenfiable data data
        </li>
      </ul>
    </ul>
    <h4>
      <b>Sign-In</b>
    </h4>
    <ul>
      <li>I’ve forgotten my password, what can I do ?</li>
      <ul>
        <li>
          On the sign-In screen you can request that a re-set password link is sent to the email
          address you registered with.
        </li>
      </ul>
      <li>My email address is not available </li>
      <ul>
        <li>
          If you didn’t previously set up an account then please contact Minvy’s support team at
          <a href="mailto:support@minvy.ai"> support@minvy.ai </a>
          providing as much information as you can to help us understand what could have happened.
        </li>
      </ul>
    </ul>
    <h4>
      <b>Minvy Assistant</b>
    </h4>
    <ul>
      <li>How can Minvy Assistant help me ?</li>
      <ul>
        <li>
          Minvy Assistant can provide lots of ways to help - from providing answers to common
          questions. Try typing “Help” in the Minvy Assistant screen or typing “FAQ” to see a
          shortened set of these questions.
        </li>
        <li>
          You can add items by voice, try speaking or typing &rdquo; Add 2 cups to kitchen &rdquo;
        </li>
        <li>Or listing items in a category by &rdquo; List items in kitchen &rdquo;</li>
      </ul>
    </ul>
    <h4>
      <b>More Help, Support and Feedback</b>
    </h4>
    <ul>
      <li>
        For all additional questions , feedback or suggestions please contact{' '}
        <a href="mailto:support@minvy.ai"> support@minvy.ai </a> and we’ll get back to you as soon
        as possible.
      </li>
    </ul>
  </div>
)

export default Help
