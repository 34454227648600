import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import Home from './pages/Home'
import InventoryDetails from './pages/InventoryDetails'
import Items from './pages/Items'
import Login from './pages/Login'
import Profile from './pages/Profile'
import ProfileCategories from './pages/Profile/ProfileCategories'
import SearchResult from './pages/SearchResult'
import SignUp from './pages/SignUp'

import Help from './pages/Help'
// import Insights from './pages/Insights'
import Reports from './pages/Reports'
import DragAndDrop from './pages/DragAndDrop'
import About from './pages/About'

const Routes = () => {
  const result = useSelector((state) => state.search.result)
  const [isLoggedIn, setIsLoggedIn] = useState(true)

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        setIsLoggedIn(true)
      }
    }
    checkIsLoggedIn()
  }, [])

  return (
    <Switch>
      {result.length && (
        <>
          <PrivateRoute path="/search-result">
            <SearchResult />
          </PrivateRoute>
          <Redirect to="/search-result" />
        </>
      )}
      <Route
        exact
        path="/"
        render={() => {
          return isLoggedIn ? <Redirect to="/home" /> : <Redirect to="/login" />
        }}
      />
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/help">
        <Help />
      </Route>
      <PrivateRoute path="/insights">
        <Reports />
      </PrivateRoute>
      <Route path="/about">
        <About />
      </Route>
      <PrivateRoute path="/drop">
        <DragAndDrop />
      </PrivateRoute>
      <PrivateRoute path="/profile/category">
        <ProfileCategories />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute path="/category/:categoryId/inventory/:inventoryId">
        <InventoryDetails />
      </PrivateRoute>
      <PrivateRoute path="/category/:categoryId">
        <Items />
      </PrivateRoute>
      <PrivateRoute path="/search-result">
        <SearchResult />
      </PrivateRoute>
      <PrivateRoute path="/home/:propertyId">
        <Home />
      </PrivateRoute>
      <PrivateRoute path="/home/">
        <Home />
      </PrivateRoute>
    </Switch>
  )
}

export default Routes
