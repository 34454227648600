import React from 'react'
const ChevronRight = () => (
  <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path d="M4.05769 3.94221L1.11548 1" stroke="#292F4F" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M1.00007 6.99999L4.05786 3.9422"
        stroke="#292F4F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
)
export default ChevronRight
