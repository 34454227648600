import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Amplify, { Auth, DataStore, Hub } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import Login from './pages/Login'
import Home from './pages/Home'
import './App.scss'
import Header from './components/Header'
import Footer from './components/Footer'
import awsconfig from './aws-exports'
import SignUp from './pages/SignUp'
import Profile from './pages/Profile'
import Items from './pages/Items'
import InventoryDetails from './pages/InventoryDetails'
import Routes from './router'
import { Category } from './models'
import { setCategories } from './actions'

Amplify.configure(awsconfig)
DataStore.configure(awsconfig)

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const onAuthStateChanged = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        // getCategories()
        setIsLoggedIn(true)
        break
      case 'signOut':
        setIsLoggedIn(false)
        break
      default:
        return false
    }
  }

  Hub.listen('auth', onAuthStateChanged)

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        setIsLoggedIn(true)
      }
    }
    checkIsLoggedIn()
  }, [])

  return (
    <Router>
      {isLoggedIn && <Header />}
      <div className="container">
        <Routes />
      </div>
      {isLoggedIn && <Footer />}
    </Router>
  )
}

export default App
