import { Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Images from '../assets/images'

const CategoryItem = ({ data }) => {
  console.log(' in Category Item')
  const history = useHistory()
  const { id, image, name } = data
  const [signedUrl, setSignedUrl] = useState('')
  useEffect(() => {
    const getSignedUrl = async () => {
      const _signedUrl = await Storage.get(image?.key, { level: 'private' })
      setSignedUrl(_signedUrl)
    }
    if (!image?.isAsset) getSignedUrl()
  }, [])

  if (!image) return null

  return (
    <div className="category" onClick={() => history.push(`/category/${id}`)}>
      {image.isAsset ? <img src={Images[image.key]} /> : <img src={signedUrl} />}
      <p>{name}</p>
    </div>
  )
}

export default CategoryItem
