import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import avatar from '../../assets/images/avatar-large.png'
import './style.scss'

const Profile = () => {
  const [user, setUser] = useState()
  const history = useHistory()

  useEffect(() => {
    const getUser = async () => {
      const _user = await Auth.currentAuthenticatedUser()
      setUser(_user)
      console.log(_user)
    }
    getUser()
  }, [])

  return (
    <div className="profile">
      <h2>Profile</h2>
      <div className="profile-userInfo">
        <img src={avatar} />
        <div className="profile-userInfo__username">
          <p>{user ? user.attributes.preferred_username : ''}</p>
          <button
            onClick={async () => {
              try {
                await Auth.signOut()
                history.push('/login')
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Sign out
          </button>
        </div>
      </div>
      {/* <div className="profile-data">
        <div className="profile-data__item" onClick={() => history.push('/profile/category')}>
          <p>Categories</p>
          <p>&gt;</p>
        </div>
        <div className="profile-data__item">
          <p>Labels</p>
          <p>&gt;</p>
        </div>
        <div className="profile-data__item">
          <p>Custom fields</p>
          <p>&gt;</p>
        </div>
        <div className="profile-data__item">
          <p>Locations</p>
          <p>&gt;</p>
        </div>
      </div> */}
    </div>
  )
}

export default Profile
