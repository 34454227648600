import React from 'react'
import CategoryItem from './CategoryItem'

const Categories = ({ categories }) => {
  return (
    <div className="categoriesWrapper">
      {categories &&
        categories.map((category) => <CategoryItem key={category.id} data={category} />)}
    </div>
  )
}

export default Categories
