import React, { useState, useEffect } from 'react'
import { DataStore, SortDirection, Predicates } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CsvResult from './csvresult'
import { FaTrash } from 'react-icons/fa'
import { setCategories, setProperties } from '../../actions'
import Categories from '../../components/Categories'
import { Category } from '../../models'
import { Property } from '../../models'
import './style.scss'
// https://r2uvsma7lc.execute-api.us-west-2.amazonaws.com/default/minvyprocessamazoncsv-staging?key=order_report.csv

const DisplayParseResults = (props) => {
  // const [propertyList, setPropertyList] = useState(null)
  const [propertyList, setPropertyList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  if (props.result != null) {
    console.log('DisplayParseResults')

    const getProperties = async () => {
      // if (!propertyId) {
      // if a propertyid isn't passed through as a parameter then we pull the list of properties and use the first one as a default
      const _property = await DataStore.query(Property)

      createPropertyJson(_property)
      //create json array of properties
    }

    const createPropertyJson = (property) => {
      let output = []

      property.map((data) => {
        let propertyLocation = {
          label: data.name,
          value: data.id,
        }

        output.push(propertyLocation)
      })
      setPropertyList([...output])
    }

    const getCategories = async () => {
      var catDict = {}

      let loc_categories

      try {
        loc_categories = await DataStore.query(Category, Predicates.ALL, {
          sort: (c) => c.name(SortDirection.ASCENDING),
        })
      } catch (err) {
        console.log(err)
      }
      for (var i = 0; i < loc_categories.length; i++) {
        let categories = {
          label: loc_categories[i].name,
          value: loc_categories[i].id,
          description: loc_categories[i].propertyID,
        }

        if (loc_categories[i].propertyID in catDict) {
          catDict[loc_categories[i].propertyID].push(categories)
        } else {
          catDict[loc_categories[i].propertyID] = []
          catDict[loc_categories[i].propertyID].push(categories)
        }
      }

      setCategoryList(catDict)
    }

    useEffect(() => {
      DataStore.start()
      getProperties()
    }, [])

    useEffect(() => {
      DataStore.start()
      getCategories()
    }, [propertyList])
    if (propertyList.length > 0) {
      return (
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  Item <span className="resize-handle"></span>
                </th>
                <th>
                  Category <span className="resize-handle"></span>
                </th>
                <th>
                  Notes<span className="resize-handle"></span>
                </th>
                <th>
                  Date <span className="resize-handle"></span>
                </th>
                <th>
                  Price <span className="resize-handle"></span>
                </th>
                <th>
                  # <span className="resize-handle"></span>
                </th>
                <th>
                  Location <span className="resize-handle"></span>
                </th>
                <th>
                  <FaTrash />
                  <span className="resize-handle"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.result &&
                props.result.is_deleted != 'true' &&
                props.result.map((data, i) => (
                  <CsvResult
                    key={i}
                    data={data}
                    index={i}
                    setSelected
                    toggleSelected={props.toggleSelected}
                    propertyList={propertyList}
                    categoryList={categoryList}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )
    } else {
      return <div />
    }
  }
  return <div>Unable to Parse Data</div>
}

export default DisplayParseResults

// const colourLocations = [
//   { value: '001', label: 'Primnary', description: 'Test description for red' },
//   { value: '002', label: 'Storage', description: 'Test description for green' },
//   { value: '003', label: 'Lockup', description: 'Test description for blue' },
// ]
