import React from 'react'
import { useGoogleLogin } from 'react-google-login'
import google from '../../assets/images/google.svg'
import { useHistory } from 'react-router-dom'
// refresh token
import { refreshTokenSetup } from '../../utils/refreshToken'

const clientId = '188644014140-0bfge68upgcq29bc4dp01vr7hure3fcq.apps.googleusercontent.com'

function GoogleLogin() {
  const history = useHistory()
  const onSuccess = (res) => {
    // history.push('/profile')
    console.log('Login Success: currentUser:', res.profileObj)
    alert(`Logged in successfully welcome ${res.profileObj.name} .`)
    refreshTokenSetup(res)
  }

  const onFailure = (res) => {
    console.log('Login failed: res:', res)
    alert(`Failed to login.`)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    //  responseType: 'code',
    // prompt: 'consent',
  })

  return (
    <button onClick={signIn} className="button">
      <img src={google} alt="google login" className="icon"></img>

      <span className="buttonText">Sign in with Google</span>
    </button>
  )
}

export default GoogleLogin
