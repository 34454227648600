import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'

const ProductImages = ({ images }) => {
  const [imageUrls, setImageUrls] = useState(Array(5).join('.').split('.'))

  useEffect(() => {
    const getSignedUrl = async () => {
      const _imageUrls = Array(5).join('.').split('.')
      if (images && images.length) {
        for (const [index, image] of images.entries()) {
          if (!image?.isAsset) {
            const signedUrl = await Storage.get(image?.key, { level: 'private' })
            _imageUrls[index] = signedUrl
          }
        }
        setImageUrls(_imageUrls)
      }
    }
    getSignedUrl()
  }, [images])

  return (
    <div className="inventoryDetails-images">
      <div className="inventoryDetails-images__main">
        <img src={imageUrls[0]} />
      </div>
      <div className="inventoryDetails-images__smallImagesWrapper">
        {imageUrls.map(
          (url, index) =>
            index > 0 &&
            index < 5 && (
              <div key={`${url}-${index}`} className="inventoryDetails-images__smallImage">
                {url && <img src={url} />}
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default ProductImages
