import React, { useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import './style.scss'
import { HashLink } from 'react-router-hash-link'

const AboutButton = () => {
  const [visible, setVisible] = useState(false)

  const referenceRef = useRef(null)
  const node = useRef()
  const popperRef = useRef(null)
  const history = useHistory()

  const routeChange = () => {
    console.log('route change')
    let path = '/about'
    history.push(path)
  }
  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [visible])

  const handleClickOutside = (e) => {
    if (node && node.current && node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setVisible(false)
  }

  function handleDropdownClick(event) {
    const { id } = event.target
    if (id == 'about_button') {
      routeChange(id)
    }
    setVisible(!visible)
  }

  return (
    <React.Fragment>
      <div ref={node} className="select-container" onClick={() => setVisible(!visible)}>
        <button
          id="about_button"
          className="top-button"
          ref={referenceRef}
          onClick={handleDropdownClick}
        >
          About
        </button>

        <div ref={popperRef} style={styles.popper} {...attributes.popper}>
          <DropdownContainer visible={visible}>
            <DropdownItem id="agreement">
              <HashLink
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                // example of custom scroll function using the scroll prop
                scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                to="/about#agreementa"
              >
                Agreement
                <br />
              </HashLink>
            </DropdownItem>
            <DropdownItem id="privacy">
              <HashLink
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                // example of custom scroll function using the scroll prop
                scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                to="/about#privacya"
              >
                Privacy
              </HashLink>
            </DropdownItem>
            <DropdownItem id="aboutminvy">
              <HashLink
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                // example of custom scroll function using the scroll prop
                scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                to="/about#aboutminvya"
              >
                About Minvy
              </HashLink>
            </DropdownItem>
            <DropdownItem id="contact">
              <HashLink
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                // example of custom scroll function using the scroll prop
                scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                to="/about#emailus"
              >
                Contact Minvy
              </HashLink>
            </DropdownItem>
          </DropdownContainer>
        </div>
      </div>
    </React.Fragment>
  )
}

const DropdownContainer = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  width: '2px';
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  padding: 5px;
`

const DropdownItem = styled.div`
  justify-content: flex-start;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;

  &:hover {
    background-color: #ebf2fa;
  }
  &:active {
    font-weight: 700;
    color: white;
  }
`

// const ButtonStyle = styled.button`
//   display: inline-flex;
//   flex-grow: 0;
//   font-size: 15px;
//   font-weight: 500;
//   line-height: 18px;
//   border-radius: 16px;
//   height: 28px;
//   min-width: 50px;
//   width: auto;
//   padding: 0 16px;
//   &:hover {
//     cursor: pointer;
//   }
//   background: black;
// `
export default AboutButton
