import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DataStore } from 'aws-amplify'
import { debounce, startCase, toLower } from 'lodash'

import { setSearchResult } from '../actions/search.actions'
import logo from '../assets/images/logo.png'
import avatar from '../assets/images/avatar.png'
import { Item } from '../models'
import { removeDuplicates } from '../utils/helper'
import AboutButton from './AboutButton'
import LocationButton from './LocationButton'

const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const search = async (e) => {
    if (!e.target.value) {
      dispatch(setSearchResult([]))
      return
    }
    try {
      const _result = await DataStore.query(Item, (c) =>
        c.or((c) =>
          c
            .name('beginsWith', e.target.value.toUpperCase())
            .name('beginsWith', e.target.value.toLowerCase())
            .name('beginsWith', startCase(toLower(e.target.value)))
        )
      )
      if (_result && _result.length) {
        const key = 'recentKeywords'
        const recentKeywords = localStorage.getItem(key)
          ? JSON.parse(localStorage.getItem(key))
          : []
        recentKeywords.push(e.target.value)
        localStorage.setItem(key, JSON.stringify(removeDuplicates(recentKeywords)))
        dispatch(setSearchResult(_result))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const debounceSearch = debounce(search, 1000)

  return (
    <div className="header">
      <img className="header-logo" src={logo} />
      <div className="header-nav">
        {/* <Link
          className={`header-nav__item ${window.location.pathname === '/home' ? 'active' : ''}`}
          to="/home"
        >
          Home
        </Link> */}
        <div
          className={`header-nav__item ${window.location.pathname === '/location' ? 'active' : ''}`}
          to="/home"
        >
          <LocationButton />
        </div>
        <Link
          className={`header-nav__item ${window.location.pathname === '/insights' ? 'active' : ''}`}
          to="/insights"
        >
          Insights
        </Link>
      </div>
      <input className="header-searchInput" placeholder="Search" onChange={debounceSearch} />
      <div className="header-nav">
        <Link
          className={`header-nav__item ${window.location.pathname === '/drop' ? 'active' : ''}`}
          to="/drop"
        >
          Upload
        </Link>
        <Link
          className={`header-nav__item ${window.location.pathname === '/help' ? 'active' : ''}`}
          to="/help"
        >
          Help
        </Link>
        <div
          className={`header-nav__item ${window.location.pathname === '/aboutlp' ? 'active' : ''}`}
          to="/about"
        >
          <AboutButton />
        </div>
      </div>

      <img
        className="header-avatar"
        src={avatar}
        onClick={() => {
          history.push('/profile')
        }}
      />
    </div>
  )
}

export default Header
