import React, { useEffect } from 'react'
import { Auth, DataStore } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Category } from '../../models'
import { setCategories } from '../../actions'
import './style.scss'
import Categories from '../../components/Categories'
import ChevronRight from '../../components/ChevronRight'

const ProfileCategories = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const categories = useSelector((state) => state.categories)

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = async () => {
    try {
      const _categories = await DataStore.query(Category)
      dispatch(setCategories(_categories))
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="categories">
      <div className="categories-header">
        <div>
          <p className="categories-header__route">
            <Link to="/profile">
              <span>Profile</span>
            </Link>
            <ChevronRight />
            <Link to="/profile/category">
              <span>Categories</span>
            </Link>
          </p>
          <h2 className="categories-header__title">Categories</h2>
        </div>
        <button className="categories-header__add">Add</button>
      </div>
      <Categories categories={categories} />
      <button
        onClick={async () => {
          try {
            await Auth.signOut()
            history.push('/login')
          } catch (error) {
            console.log(error)
          }
        }}
      >
        Log out
      </button>
    </div>
  )
}

export default ProfileCategories
