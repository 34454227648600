import { Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'

const PieChartCost = (props) => {
  const img = document.getElementById('costPieChartImage')
  const [signedUrl, setSignedUrl] = useState('')
  const [imgLarge, setImgLarge] = useState(false)
  useEffect(() => {
    const getSignedUrl = async () => {
      let fileName = 'user-reports/' + props.userID + '___All__cvpie_cost.png'
      const _signedUrl = await Storage.get(fileName, { level: 'guest' })
      setSignedUrl(_signedUrl)
    }
    getSignedUrl()
  })
  const enlargeImg = () => {
    // Set image size to 1.5 times original
    if (imgLarge == false) {
      img.style.transform = 'scale(2.0)'
      // Animation effect
      img.style.transition = 'transform 0.25s ease'
      setImgLarge(true)
    } else {
      img.style.transform = 'scale(1)'
      img.style.transition = 'transform 0.25s ease'
      setImgLarge(false)
    }
  }
  return (
    <div>
      <div className="pie_chart_small">
        <br />
        <br />
        <br />
        <br />
        <h3> Total Original Cost Per Category </h3>
        <br />
        <br />
        <img src={signedUrl} onClick={enlargeImg} id="costPieChartImage" />
      </div>
    </div>
  )
}

export default PieChartCost
