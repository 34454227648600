import { Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'

const Document = ({ document }) => {
  const [url, setUrl] = useState('')
  const [thumb, setThumb] = useState(null)

  useEffect(() => {
    const getSignedUrl = async () => {
      if (document.thumbnail) {
        const signedUrl = await Storage.get(document.thumbnail.key, { level: 'private' })
        setThumb(signedUrl)
      }
    }
    getSignedUrl()
  }, [])

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob)
    const a = window.document.createElement('a')
    a.href = url
    a.download = filename || 'download'
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener('click', clickHandler)
      }, 150)
    }
    a.addEventListener('click', clickHandler, false)
    a.click()
    return a
  }

  const handleDownload = async () => {
    try {
      if (document.file) {
        console.log(document.file)
        const result = await Storage.get(document.file.key, { level: 'private', download: true })
        downloadBlob(result.Body, document.fileName)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="inventoryDetails-details__document" onClick={handleDownload}>
      <img src={thumb} />
    </div>
  )
}

export default Document
