import { DataStore } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Label } from '../models'
import './style.scss'

const LabelComponent = ({ data }) => {
  const [label, setLabel] = useState()
  useEffect(() => {
    const getLabel = async () => {
      try {
        const _label = await DataStore.query(Label, (c) => c.id('eq', data.labelID))
        setLabel(_label[0])
      } catch (err) {
        console.log(err)
      }
    }
    getLabel()
  }, [])
  return <p className="label">{label?.name}</p>
}

export default LabelComponent
