import React, { useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DataStore } from 'aws-amplify'
import { Property } from '../models'
import { setProperties } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { HashLink } from 'react-router-hash-link'

const LocationButton = () => {
  const [visible, setVisibility] = useState(false)
  const [locationName, setLocationName] = useState('Location')

  const referenceRef = useRef(null)
  const popperRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const properties = useSelector((state) => state.properties.data)

  const getProperties = async () => {
    console.log('properties : getProperties : start')
    const _property = await DataStore.query(Property)
    dispatch(setProperties(_property))
    if (_property.length == 1) {
      setLocationName('Categories')
    }
  }
  const handleLocationClick = (propertyId, propertyName) => () => {
    console.log('handleLocationClick')
    console.log(propertyId)
    if (properties.length > 1) {
      setVisibility(!visible)
      setLocationName(propertyName)
      setVisibility(!visible)
    }
    history.push(`/home/${propertyId}`)
    setVisibility(!visible)
    //  routeChange(propertyId)
  }
  useEffect(() => {
    console.log('properties : useEffect : start')
    DataStore.start()
    getProperties()
  }, [])

  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 10],
        },
      },
    ],
  })
  useEffect(() => {
    //listen for clicks and close dropdown on body
    // document.addEventListener('mouseup', handleDropdownClick)
    return () => {
      // setVisibility(!visible)
      document.removeEventListener('mouseup', handleDropdownClick)
    }
  }, [])

  function handleDropdownClick(event) {
    const { id } = event.target
    console.log(id)
    if (id == 'location_button') {
      // routeChange(id)
      setVisibility(!visible)
      return
    } else {
      setVisibility(!visible)
    }
  }

  return (
    <React.Fragment>
      <button
        id="location_button"
        className="top-button"
        ref={referenceRef}
        onClick={handleDropdownClick}
      >
        {locationName}
      </button>

      <div ref={popperRef} style={styles.popper} {...attributes.popper}>
        <DropdownContainer visible={visible}>
          {properties &&
            properties.map((property) => (
              <DropdownItem
                key={property.id}
                onClick={handleLocationClick(property.id, property.name)}
              >
                <HashLink
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                  // example of custom scroll function using the scroll prop
                >
                  {property.name}
                  <br />
                </HashLink>
              </DropdownItem>
            ))}
        </DropdownContainer>
      </div>
    </React.Fragment>
  )
}

const DropdownContainer = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  width: '2px';
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  padding: 5px;
`

const DropdownItem = styled.div`
  justify-content: flex-start;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;

  &:hover {
    background-color: #ebf2fa;
  }
  &:active {
    font-weight: 700;
    color: white;
  }
`
export default LocationButton
