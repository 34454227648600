import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import categories from './categories.reducer'
import products from './products.reducer'
import search from './search.reducer'
import properties from './properties.reducer'

export default (history) =>
  combineReducers({
    categories,
    products,
    search,
    properties,
    router: connectRouter(history),
  })
