// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Condition = {
  "NEW": "NEW",
  "LIKENEW": "LIKENEW",
  "VERYGOOD": "VERYGOOD",
  "GOOD": "GOOD",
  "FAIR": "FAIR",
  "POOR": "POOR"
};

const RecognitionJobStatus = {
  "DONE": "DONE",
  "INPROGRESS": "INPROGRESS",
  "CANCELLED": "CANCELLED",
  "FAILED": "FAILED"
};

const CsvParsingJobStatus = {
  "DONE": "DONE",
  "CREATED": "CREATED",
  "INPROGRESS": "INPROGRESS",
  "CANCELLED": "CANCELLED",
  "FAILED": "FAILED"
};

const FileType = {
  "ID": "ID",
  "IMAGE": "IMAGE",
  "PDF": "PDF"
};

const InsuranceType = {
  "HOME": "HOME",
  "AUTO": "AUTO",
  "EARTHQUAKE": "EARTHQUAKE",
  "RENTERS": "RENTERS"
};

const UserType = {
  "ADMIN": "ADMIN",
  "READONLY": "READONLY",
  "READWRITE": "READWRITE"
};

const BookType = {
  "ID": "ID",
  "PAPERBACK": "PAPERBACK",
  "HARDCOVER": "HARDCOVER",
  "KINDLEEDITION": "KINDLEEDITION",
  "AUDIBLEAUDIOEDITION": "AUDIBLEAUDIOEDITION",
  "HTML": "HTML",
  "PDF": "PDF",
  "AUDIOCD": "AUDIOCD",
  "BOARDBOOK": "BOARDBOOK",
  "AUDIOCASSETTE": "AUDIOCASSETTE",
  "CALENDAR": "CALENDAR",
  "SCHOOLBINDING": "SCHOOLBINDING",
  "MPECD": "MPECD",
  "AUDIOBOOKS": "AUDIOBOOKS",
  "PRINTEDBOOKS": "PRINTEDBOOKS"
};

const { CustomField, LabelItem, RecognitionJob, CSVParsingJob, RecentSearchQueries, Item, Insurance, User, Beneficiary, Label, Property, Category, VehicleMetadata, BookMetadata, S3Object, Attachment } = initSchema(schema);

export {
  CustomField,
  LabelItem,
  RecognitionJob,
  CSVParsingJob,
  RecentSearchQueries,
  Item,
  Insurance,
  User,
  Beneficiary,
  Label,
  Property,
  Category,
  Condition,
  RecognitionJobStatus,
  CsvParsingJobStatus,
  FileType,
  InsuranceType,
  UserType,
  BookType,
  VehicleMetadata,
  BookMetadata,
  S3Object,
  Attachment
};