import React, { useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'

import { Category } from '../../models'
import { setCategories, setProperties } from '../../actions'
import { useParams } from 'react-router-dom'
import Categories from '../../components/Categories'
import { Property } from '../../models'
import './style.scss'

const Home = () => {
  console.log('in Home')
  const { propertyId } = useParams()

  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories)
  const properties = useSelector((state) => state.properties.data)

  const getProperties = async () => {
    if (!propertyId) {
      // if a propertyid isn't passed through as a parameter then we pull the list of properties and use the first one as a default
      const _property = await DataStore.query(Property)
      dispatch(setProperties(_property))

      const propId = _property[0].id

      getCategories(propId)
    } else {
      getCategories(propertyId)
    }
  }
  const getCategories = async (propId) => {
    // const _categories = await DataStore.query(Category, (c) => c.propertyID('eq', propId))
    const _categories = await DataStore.query(Category, (c) => c.propertyID('eq', propId), {
      sort: (c) => c.name(SortDirection.ASCENDING),
    })
    dispatch(setCategories(_categories))
  }

  useEffect(() => {
    console.log('categories : useEffect : start')
    DataStore.start()
    getProperties()
  }, [])

  return (
    <div className="home-title">
      <h2>Categories</h2>
      <Categories categories={categories} />
    </div>
  )
}

export default Home
